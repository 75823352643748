import React, { useEffect,useState,useContext }  from 'react';
import { AppContext } from '../../AppContext';
import styles from './Login.module.css';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider,onAuthStateChanged, GithubAuthProvider } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css'
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4YKRECpOuFp5Q7QFLmrZrDlkLrRn0YAE",
  authDomain: "snipdragon-2024.firebaseapp.com",
  projectId: "snipdragon-2024",
  storageBucket: "snipdragon-2024.appspot.com",
  messagingSenderId: "827761645269",
  appId: "1:827761645269:web:10470b45120328bfad745c",
  measurementId: "G-F5T27JKD69"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const ui = new firebaseui.auth.AuthUI(auth);

const Login = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const {userAuthenticated,setUserAuthenticated } = useContext(AppContext);
  useEffect(() => {
    // onAuthStateChanged(auth, (user) => {
    //   setIsAuthenticated(!!user);
    // });
 
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult) {
          console.log('::::!!:', JSON.stringify(authResult));
          setUserAuthenticated(authResult)
          setIsAuthenticated(true);
          return false; // Prevent redirect.
        },
        uiShown: function() {
          document.getElementById('loader').style.display = 'none';
        }
      },
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ],
    };
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <>

      <div className={styles.Login}>
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
      </div>
          
          {
            isAuthenticated && (
              <div>
              <div className={styles.loggedinmenu}>
                <div>
                  Profile
                </div>
                <div>
                  Settings
                </div>
                <div>
                  Teams
                </div>
              </div>
              </div>
            )
          }
     

      </>
  )

}





export default Login;