import React, { useState, useEffect,useContext } from 'react';
import './App.css';
import { AppContext } from './AppContext';
import SnippetList from './components/SnippetList/SnippetList';
import ProductHeader from './components/ProductHeader/ProductHeader';
import { Flex, Layout, Input, Button, Modal, Form, Select } from 'antd';
import Login from './components/Login/Login';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { addSnippetToUser } from './utils/api';


const { Header, Footer, Content } = Layout;
const { Option } = Select;

const headerStyle = {
  textAlign: 'center',
  height: '5rem',
  padding: '1rem',
  backgroundColor: '#fff',
  justify: 'center'
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  padding: '1rem',
  backgroundColor: '#fff'
};

const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#4096ff'
};

const layoutStyle = {};

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [snippets, setSnippets] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {userAuthenticated } = useContext(AppContext);
 
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const text = e.dataTransfer.getData('text/plain');
    if (text) {
      const newSnippet = {
        name: "New Snippet",
        id: Date.now().toString(),
        description: "Snippet added via drag and drop",
        created: new Date().toLocaleDateString(),
        modified: new Date().toLocaleDateString(),
        language: "Text",
        body: text
      };
      setSnippets([...snippets, newSnippet]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addSnippet = (name, description, language, body) => {
    const newSnippet = {
      name: name,
      id: Date.now().toString(),
      description: description,
      created: new Date().toLocaleDateString(),
      modified: new Date().toLocaleDateString(),
      language: language,
      body: body
    };
   // setSnippets([...snippets, newSnippet]);
   addSnippetToUser( "rnWXWznHq1NUDqHe4cZ99lxOVYt2",newSnippet) 
   
   setIsModalVisible(false);
  };

  const handleFormSubmit = (values) => {
    addSnippet(values.name, values.description, values.language, values.body);
  };

  return (
    <div className="App">
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <ProductHeader />
        </Header>
        <Content style={contentStyle}>
          <Flex vertical>
            <Login />
          { userAuthenticated && (


          
              <>
                <div style={{ marginBottom: '1rem' }}>
                  <Input
                    size="large"
                    placeholder="Search snippets..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '100%' }}
                  />
                </div>

                <Button type="primary" onClick={showModal} style={{ marginBottom: '1rem' }}>
                  Add Snippet
                </Button>

                <div
                  style={{
                    height: '100px',
                    width: '300px',
                    border: '2px dashed #1677ff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '1rem'
                  }}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  Drag text here to add as a snippet
                </div>

                <SnippetList 
                searchTerm={searchTerm}
                onReload={isModalVisible} />
              </>
           
           
           
           )}
          </Flex>
        </Content>
        <Footer style={footerStyle}>Footer</Footer>
      </Layout>
      <Modal
        title="Add Snippet"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the name of the snippet!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please input the description of the snippet!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true, message: 'Please select the language of the snippet!' }]}
          >
            <Select>
              <Option value="HTML">HTML</Option>
              <Option value="JavaScript">JavaScript</Option>
              <Option value="CSS">CSS</Option>
              <Option value="Swift">Swift</Option>
              <Option value="Text">Text</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="body"
            label="Body"
            rules={[{ required: true, message: 'Please input the body of the snippet!' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Snippet
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default App;