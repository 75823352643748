import React from 'react';

import styles from './SnippetCard.module.css';
import { Card,Collapse } from 'antd';

const { Panel } = Collapse;

const SnippetCard = ({ name, description, created, modified, body }) => (
  <div className={styles.SnippetCard}>
    <Card>
      <Card.Meta
        title={name}
        description={
          <>
            <p>Created: {created}</p>
            <p>Modified: {modified}</p>
            <div style={{ fontSize: '1rem' }}>
              {description}
            </div>
          </>
        }
      />
    <Collapse 
    style={{ marginTop: '1rem' }}
     bordered={false}>
        <Panel header="Preview" key="1">
          <pre style={{ background: '#2d2d2d', 
            color: '#f8f8f2', 
            padding: '1rem', 
            borderRadius: '5px', 
            overflowX: 'auto',
            whiteSpace: 'pre-wrap'  }}>
            
            {body}</pre>
        </Panel>
      </Collapse>
    </Card>
  </div>
);




export default SnippetCard;
