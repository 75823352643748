import React, { useState, useEffect,useContext } from 'react';
import styles from './SnippetList.module.css';
import { Flex } from 'antd';
import SnippetCard from '../SnippetCard/SnippetCard';
import {addSnippetToUser,fetchAllSnippetsByUser} from './../../utils/api'


//TODO : add context for username


//get snippets based on username


function dragCard(event, body) {
  const formattedBody = `\r\n \r\n ${body} \r\n \r\n`;
  event.dataTransfer.setData('text/plain', formattedBody);

}

const loadSnippets = async (userId) => {
  try {
    const snippets = await fetchAllSnippetsByUser(userId);
    return snippets;
  } catch (error) {
    console.error("Error loading snippets: ", error);
    return [];
  }
};

const SnippetList = ({ searchTerm,onReload }) => {

  const [snippets, setSnippets] = useState([]);

  useEffect(() => {
    const userId = "rnWXWznHq1NUDqHe4cZ99lxOVYt2";
     // Load snippets from Firestore
     const fetchSnippets = async () => {
      const snippetsFromDb = await loadSnippets(userId);
      setSnippets(snippetsFromDb);
    };

    fetchSnippets();
  }, [onReload]);

  

  const filteredSnippets = snippets.filter(snippet => 
    snippet.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    snippet.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    snippet.language.toLowerCase().includes(searchTerm.toLowerCase())
  );

return(
  <div className={styles.SnippetList}>
      <Flex gap="middle" vertical>
        {filteredSnippets.map(snippet => (
          <div key={snippet.id} draggable="true" onDragStart={(e) => dragCard(e, snippet.body)}>
            <SnippetCard
              name={snippet.name}
              description={snippet.description}
              created={snippet.created}
              modified={snippet.modified}
              body={snippet.body}
              language={snippet.language}
            />
          </div>
        ))}
      </Flex>
  </div>
)
};



export default SnippetList;
